<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="9">
          <b-card-group>
            <b-card no-body class="p-5 card-forgot-password">
              <b-card-body class="mt-3">
                <validation-observer
                  ref="forgotPasswordForm"
                  #default="{invalid}"
                >
                <b-form @submit.prevent="onLogin">
                  <loading
                    :active.sync="isLoading"
                    :can-cancel="false"
                    :is-full-page="fullPage"
                  ></loading>
                  <center>
                    <div class="mb-2">
                      <h1>
                        ¿Olvidaste tu contraseña?
                      </h1>
                    </div>
                    <div class="mb-3">
                        <h4>
                          Te enviaremos tu nueva contraseña por email
                        </h4>
                    </div>
                  </center>

                  <b-form-group label="Correo electrónico">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email|min:5"
                    >
                    <b-form-input
                      size="sm"
                      type="text"
                      v-model="user.email"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="email"
                    ></b-form-input>
                     <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-row>
                    <b-col cols="12">
                      <br />
                      <center>
                        <b-button @click="onReset()" :disabled="invalid" class="md-primary md-width mt-3 w-100"
                          >RECUPERAR CONTRASEÑA</b-button
                        >
                        <br />
                      </center>
                    </b-col>
                  </b-row>
                </b-form>
                </validation-observer>
              </b-card-body>
            </b-card>
            <b-card no-body class="text-white py-8 kenburns-left d-md-down-none card-forgot-password-register">
              <div class="text-center center-abs">
                <br />
                <div class="mb-5">
                  <h2 class="text-white">
                    <b>Registrate</b>
                  </h2>
                  <h5 class="text-white">
                    Si sos nuevo, registrá tu cuenta
                  </h5>
                </div>
                <div>
                  <b-button @click="go_register" class="md-secondary md-width rounded-pill p-1">REGISTRARSE</b-button>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import UserService from "../../../services/UserService";
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCardGroup, BCard, BCardBody
} from 'bootstrap-vue'

export default {
  name: "ForgotPassword",
  components:{
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardGroup,
    BCard,
    BCardBody,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      user: { email: null },
    };
  },
  methods: {
    go_register() {
      this.$router.push("/register");
    },
    onReset() {
      this.$refs.forgotPasswordForm.validate().then((valid) => {
        if (valid) {
          this.isLoading = true;
          UserService.forgot_password({
            email: this.user.email,
          })
            .then((response) => {
              this.isLoading = false;
              if (response.data) {
                switch (response.data.message) {
                  case "Email could not be sent to this email address.": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Datos invalidos`,
                        text: `No se encontro un usuario asociado a ese email`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                    break;
                  }
                  case "Password reset email sent.": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Exito`,
                        text: `Revisa tu bandeja de emails para seguir al paso 2`,
                        icon: "CheckIcon",
                        variant: "success",
                      },
                    });
                    break;
                  }
                }
              }
            })
            .catch((reason) => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Revisa tu bandeja de emails`,
                  text: `Se envio un email`,
                  icon: "AlertTriangleIcon",
                  variant: "warning",
                },
              });
              if (reason.response) {
                switch (reason.response.data.message) {
                  case "Please Verify Email": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Revisa tu bandeja de emails`,
                        text: `Es necesario Verificar el email`,
                        icon: "AlertTriangleIcon",
                        variant: "warning",
                      },
                    });
                    break;
                  }
                  case "Account Disabled": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Usuario deshabilitado`,
                        text: `El usuario aun esta pendiente por activar`,
                        icon: "AlertTriangleIcon",
                        variant: "warning",
                      },
                    });
                    break;
                  }
                  case "Unauthorized": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Datos invalidos`,
                        text: `Usuario o contraseña invalidos`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                    break;
                  }
                }
              }
            });
        }
      });
    },
  },
  computed: {},
};
</script>
<style lang="scss">
.card-pass {
  border: 0;
  border-radius: 1rem;
  .card-body h3 {
    line-height: 1.5em;
    color: #f6770d;
    font-size: 1.73125rem;
    font-weight: 700;
  }
}

.card-group{
  max-height: 530px;
  height: 520px;
}

.md-secondary{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #f6770d !important;
  font-weight: 900;
}
.md-secondary:active,
.md-secondary:focus{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #f6770d !important;
}

.bg-gray-default {
  background: #f3f3f3;
}
.md-primary {
  background-image: linear-gradient(to right, #f68d1e 5%, #f05822 100%) !important;
  border-radius: 25px;
  border: none;
  font-size: 12px;
  padding: 15px;
}
h3 {
  color: #838890;
}
h1{
  color: #f6770d;
  font-weight: 700;
}
.btn-link {
  font-size: 0.7rem;
  text-decoration: underline;
}
.center-abs {
  position: relative !important;
  left: 0 !important;
  top: 160px !important;
}
.py-8 {
  height: auto;
  background-image: url("/img/auth_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.card-forgot-password{
  border-radius: 1rem 0 0 1rem !important;
}
.card-forgot-password-register{
  border-radius: 0 1rem 1rem 0 !important;
}
</style>
